<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="customerData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.customers.clientNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-customer-list' }">
          {{ $t("message.customers.clientList") }}
        </b-link>
        {{ $t("message.customers.forOtherCustomers") }}
      </div>
    </b-alert>

    <template v-if="customerData">
      <div>
        <b-card>
          <h2 class="mb-4">{{ $t("message.customers.viewCustomer") }}</h2>
          <b-row>
            <b-col md="8">
              <b-form-group label-for="nameCustomers">
                <template v-slot:label>
                  {{ $t("message.tableHeader.name") }}
                </template>
                <b-form-input
                  v-model="customerData.name"
                  class="mt-0"
                  label="name"
                  label-for="nameCustomers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="cnpjCustomers">
                <template v-slot:label>
                  {{ $t("message.register.cnpj") }}
                </template>
                <b-form-input
                  v-model="customerData.identificationNumber"
                  class="mt-0"
                  label="cnpj"
                  label-for="cnpjCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label-for="phoneNumbersCustomers">
                <template v-slot:label>
                  {{ $t("message.register.phone") }}
                </template>
                <b-form-input
                  v-model="phones"
                  class="mt-0"
                  label="phoneNumbers"
                  label-for="phoneNumbersCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label-for="emailsCustomers">
                <template v-slot:label>
                  {{ $t("message.register.email") }}
                </template>
                <b-form-input
                  v-model="emails"
                  class="mt-0"
                  label="emails"
                  label-for="emailsCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="contactsCustomers">
                <template v-slot:label>
                  {{ $t("message.register.contact") }}
                </template>
                <v-select
                  multiple
                  v-model="customerData.contacts"
                  :options="userOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-users"
                  class="mt-0"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5">
              <b-form-group label-for="addresCustomers">
                <template v-slot:label>
                  {{ $t("message.register.address") }}
                </template>
                <b-form-input
                  v-model="customerData.address"
                  class="mt-0"
                  label="addresCustomers"
                  label-for="addresCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="cepCustomers">
                <template v-slot:label>
                  {{ $t("message.register.zipCode") }}
                </template>
                <b-form-input
                  v-model="customerData.zipCode"
                  class="mt-0"
                  label="cpCustomers"
                  label-for="cpCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="districtCustomers">
                <template v-slot:label>
                  {{ $t("message.register.district") }}
                </template>
                <b-form-input
                  v-model="customerData.district"
                  class="mt-0"
                  label="districtCustomers"
                  label-for="districtCustomers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="cityCustomers">
                <template v-slot:label>
                  {{ $t("message.register.city") }}
                </template>
                <b-form-input
                  v-model="customerData.city"
                  class="mt-0"
                  label="cityNumbers"
                  label-for="cityCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label-for="ufCustomers">
                <template v-slot:label>
                  {{ $t("message.register.uf") }}
                </template>
                <b-form-input
                  v-model="customerData.uf"
                  class="mt-0"
                  label="ufCustomers"
                  label-for="ufCostumers"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>

    <b-row>
      <b-col>
        <div class="text-right mt-2 mr-2 mb-2">
          <b-button
            @click="
              $router.push({
                name: 'apps-customer-edit',
                params: { id: customerData.id },
              })
            "
            variant="primary"
            v-if="restrictions('button_edit_customer')"
          >
            {{ $t("message.buttons.edit") }}
          </b-button>
          <b-button
            v-b-modal.modal-delete-confirmation
            variant="outline-danger"
            class="ml-1"
            v-if="restrictions('button_delete_customer')"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="modal-delete-confirmation"
      modal-class="modal-danger"
      centered
      :title="$t('delete')"
      hide-footer
    >
      <b-card-text> {{ $t("message.deletAcount") }}</b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          :to="{ name: 'apps-customer-list' }"
          @click="deleteCustomer()"
        >
          {{ $t("message.buttons.delete") }}
        </b-button>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BCardText,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BCardText,
  },
  data() {
    return {
      //select \\
      userOptions: [],
      teamOptions: [],
      membersOptions: [],
      //multilingual i18n \\
      locale: this.$i18n.locale,

      emails: "",
      phones: "",

      customerData: [],
      userData: store.state.user.userData,
    };
  },

  async created() {
    await axios({
      method: "get",
      url: `${URL_API}customer/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    })
      .then((response) => {
        this.customerData = response.data;
        this.emails = response.data.emailAdresses.join(",");
        this.phones = response.data.phoneNumbers.join(",");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.customerData = undefined;
        }
      });

    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({
            label: userList[i],
            value: userId[i],
          });
        }
      });
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    updateCustomer() {
      axios({
        method: "put",
        url: `${URL_API}customer/${this.customerData.id}`,
        headers: getHeader(this.userData),
        data: this.customerData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    deleteCustomer() {
      axios({
        method: "delete",
        url: `${URL_API}customer/${this.customerData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O cliente ${this.customerData.name} foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            taskData.value = undefined;
          }
        });
    },
  },
};
</script>
